<template>
    <div class="quick-quote">
        <div class="row">
            <div class="col-sm-12 col-md-4 col-md-offset-1">
                <OfLocationInput class="quick-quote__input"
                                 label="Sender Suburb / Postcode"
                                 :countryCode="countryCode"
                                 :error="$v.from.$error"
                                 v-model="from"></OfLocationInput>
            </div>

            <div class="col-sm-12 col-md-4 col-md-offset-1">
                <OfLocationInput class="quick-quote__input"
                                 label="Receiver Suburb / Postcode"
                                 :countryCode="countryCode"
                                 :error="$v.to.$error"
                                 v-model="to"></OfLocationInput>
            </div>
        </div>

        <InputTable :columns="columns"
                    :items.sync="items"
                    :model="$v.items"
                    ref="quoteItemTable">
        </InputTable>

        <div class="quick-quote__footer">
            <QuoteItemsSummary class="quick-quote__summary" :items="items"></QuoteItemsSummary>

            <div class="quick-quote__actions">
                <button type="submit" class="quick-quote__row__btn btn btn-primary btn-block"
                        @click="quote">
                    <span class="glyphicon glyphicon-check" aria-hidden="true"></span> Calculate
                </button>
                <button data-clear-table type="button" class="quick-quote__row__btn btn btn-default btn-block"
                        @click="clearForm">
                    <span style="font-size: 0.95em;" class="icon-icons-delete icon-normal" aria-hidden="true"> </span>Clear
                </button>
            </div>
        </div>
        <div class="quick-quote__row quick-quote__row--end">
            <b>* Price Excluding GST</b>
        </div>

    </div>
</template>

<script>
  import InputTable from '../../consignment/InputTable'
  import OfCodeInput from '../../../components/OfCodeInput'
  import OFPackagingInput from '../../../components/OFPackagingInput'
  import OfLocationInput from '../../../components/OfLocationInput'
  import { decimal, integer, maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
  import { getQuoteItemClientModel, quotesClient } from '../../../services/api-client-service'
  import greater from '../../../validators/greater'
  import QuoteItemsSummary from './QuoteItemsSummary'

  function isIEOrEdge () {
    const ua = navigator.userAgent
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1 || ua.indexOf('Edge/') > -1
  }

  export default {
    name: 'QuickQuote',
    components: { QuoteItemsSummary, OfLocationInput, InputTable },
    props: {
        countryCode: { type: String, default: "AU" }
    },
    data () {
      return {
        items: [],
        from: null,
        to: null,
        columns: [
          {
            title: 'Code <small>(optional)</small>',
            name: 'code',
            component: OfCodeInput,
            attrs (item) {
              return {
                value: item.code
              }
            },
            events: (item) => {
              return {
                input: (option) => {
                  if (option === item.code) return

                  if ((!option || !option.width)) { // if custom code was selected
                    item.code = option

                    if (isIEOrEdge()) {
                      this.$nextTick(() => {
                        setTimeout(() => { // necessary as tab event cannot be prevented
                          this.$refs.quoteItemTable.$refs['input-packagingType'][this.items.indexOf(item)]
                            .$el
                            .querySelector('input')
                            .focus()
                        }, 100)
                      })
                    }
                    return
                  }

                  item.code = option.code
                  item.id = option.Id
                  item.description = option.name
                  item.length = option.length
                  item.width = option.width
                  item.height = option.height
                  item.weight = option.weight
                  item.dg = option.dangerousGoods ? option.dangerousGoods.length > 0 : false

                  if ((!option.packagingType || !option.packagingType.Code)) { // code with no packaging
                    return
                  }

                  item.packagingType = option.packagingType.Code
                  // we need to rerender the select to correctly setup the packaging type
                  this.packagingTypeKey += 1

                  this.$nextTick(() => {
                    setTimeout(() => { // necessary as tab event cannot be prevented
                      this.$refs.quoteItemTable.$refs['input-qty'][this.items.indexOf(item)].focus()
                    }, 100)
                  })
                }
              }
            }
          },
          {
            title: 'Packaging',
            name: 'packagingType',
            component: OFPackagingInput,
            attrs (item) {
              return {
                value: item.packagingType
              }
            },
            events: (item) => {
              return {
                input: (option) => {
                  item.packagingType = option

                  if (isIEOrEdge()) {
                    this.$nextTick(() => {
                      setTimeout(() => { // necessary as tab event cannot be prevented
                        this.$refs.quoteItemTable.$refs['input-qty'][this.items.indexOf(item)].focus()
                      }, 100)
                    })
                  }
                }
              }
            }
          },
          {title: 'Qty', name: 'qty', default: 1, width: 'minmax(55px, 8%)', attrs: {type: 'number'}},
          {title: 'Length<br>(cm)', name: 'length', width: 'minmax(55px, 8%)', attrs: {type: 'number'}},
          {title: 'Width<br>(cm)', name: 'width', width: 'minmax(55px, 8%)', attrs: {type: 'number'}},
          {title: 'Height<br>(cm)', name: 'height', width: 'minmax(55px, 8%)', attrs: {type: 'number'}},
          {title: 'Weight<br>(kg)', name: 'weight', width: 'minmax(55px, 8%)', attrs: {type: 'number', step: 'any'}},
          {
            title: 'Volume<br> (m<sup>3</sup>)', 
            name: 'volume', 
            width: 'minmax(55px, 8%)', 
            customClass: 'is-readonly', 
            component: {
              template: `<input :value="total" tabindex="-1"/>`,
              props: ['rowData'],
              computed: {
                total () {
                  return ((this.rowData.length * this.rowData.width * this.rowData.height) / 1000000).toFixed(3)
                }
              }
            }
          },
          {title: 'Dangerous Goods', name: 'dg', width: '85px', attrs: {type: 'checkbox'}}
        ]
      }
    },

    validations: {
      from: {required},
      to: {required},
      items: {
        required,
        minLength: minLength(1),
        $each: {
          reference: {maxLength: maxLength(20)},
          code: {},
          qty: {required, integer, minValue: minValue(1)},
          length: {required, decimal, positive: greater(0)},
          width: {required, decimal, positive: greater(0)},
          height: {required, decimal, positive: greater(0)},
          weight: {required, decimal, positive: greater(0)},
          packagingType: {required},
          dangerousGoods: {}
        }
      }
    },

    methods: {
      clearForm () {
        this.from = 0
        this.to = 0
        this.$set(this, 'items', [])

        this.$v.$reset()
      },

      quote () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          quotesClient.getQuote({
            senderLocation: this.from,
            receiverLocation: this.to,
            items: this.items.map(item => getQuoteItemClientModel({
              code: item.code,
              qty: item.qty,
              length: item.length,
              width: item.width,
              height: item.height,
              weight: item.weight,
              dg: item.dg,
              productId: item.productId,
              packagingType: item.packagingType
			})),
			hasDGs: _.filter(this.items, (o) => o.dg === true).length > 0 ? true : false
          }).then((html) => {
            const $quoteModal = $('#quote-modal')
            $quoteModal.find('[data-body]').html(html)
            $quoteModal.modal('show')
            $('[data-toggle-new-row]').trigger('click')
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .quick-quote__row {
        padding-right: 40px;
        margin-bottom: .5em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &--end {
            margin-bottom: .5em;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .quick-quote__footer {
        margin-right: 40px;
        margin-bottom: .5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap-reverse;

        > * {
            margin: 0.5em 0;
        }

        .quick-quote__summary {
            margin-left: auto;
        }

        .quick-quote__actions {
            display: flex;
            justify-content: flex-end;
            flex: 1;

            .quick-quote__row__btn {
                margin: 0;
                max-width: 180px;
                display: inline-block;
            }
        }
    }
</style>
