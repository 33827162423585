export var searchCodes = (search) => new Promise((resolve, reject) => {
  $.ajax({
    url: '/create-consignments/products',
    data: {searchValue: search},
    dataType: 'json',
    success: function (data) {
      resolve(data.map(code => ({
        accountId: code.AccountID,
        code: code.Code,
        id: code.Id,
        dangerousGoods: code.DangerousGoods,
        extensionData: code.ExtensionData,
        height: code.Height,
        length: code.Length,
        name: code.Name,
        productID: code.ProductID,
        weight: code.Weight,
        width: code.Width,
        packagingType: code.PackagingType
      })))
    },
    error: function (request, status, error) {
      reject(error)
    }
  })
})

export const locationClient = {
  search: ({search}, countryCode) => new Promise((resolve, reject) => {
      $.ajax({
      url: '/MyAddresses/SearchLocations/',
      type: 'GET',
      data: {
          searchValue: search,
          countryCode: countryCode
      },
      dataType: 'json',
      success: function (locations) {
        resolve(locations.map(location => ({
          countryCode: location.CountryCode,
          locality: location.Locality,
          id: location.LocationID,
          postcode: location.Postcode,
          state: location.State,
        })))
      },
      error: function (request, status, error) {
        reject(error)
      }
    })
  })
}

/****************** Quotes client ******************/

export const getQuoteItemClientModel =
    ({
        code = '',
        qty = 0,
        length = 0,
        width = 0,
        height = 0,
        weight = 0,
        dg = false,
        productId = 0,
        packagingType = ''
    }) => {
        return {
            'NoItems': qty,
            'Length': length,
            'Width': width,
            'Height': height,
            'Weight': weight,
            'Volume': (length * height * width / 1000000).toFixed(3),
            'dg': dg,
            'ItemType': code,
            'ProductID': productId,
            'PackagingType': packagingType
        };
    };

export const quotesClient = {
  getQuote: ({senderLocation, receiverLocation, items, hasDGs}) =>
    new Promise((resolve, reject) => {
        $.ajax({
            url: "/Home/GetQuotes",
            type: "POST",
            data: {
                isReceiverResidential: false,
                senderLocationID: senderLocation,
                isSenderResidential: false,
                receiverLocationID: receiverLocation,
                isPOBox: false,
                insuranceValue: 0,
                conItems: items,
                hasDGs: hasDGs
            },
            dataType: "html",
            success: function(result) {
                resolve(result);
            },
            error: function(request, status, error) {
                reject(error);
            }
        });
    })
}

/****************** Packaging client ******************/

export const packagingTypeClient = {
  fetch: () => new Promise((resolve, reject) => {
    $.ajax({
      url: '/packaging',
      type: 'GET',
      dataType: 'json',
      success: function (packagingTypes) {
        resolve(packagingTypes.map(packaging => ({
          code: packaging.Code,
          description: packaging.Description
        })))
      },
      error: function (request, status, error) {
        reject(error)
      }
    })
  })
}